import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-do-multiple',
  templateUrl: './do-multiple.component.html',
  styleUrls: ['./do-multiple.component.scss']
})
export class DoMultipleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
