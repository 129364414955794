import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SumOfTenComponent } from './sum-of-ten/sum-of-ten.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StarComponent } from './star/star.component';
import { DoMultipleComponent } from './do-multiple/do-multiple.component';
import { TimerContainerComponent } from './timer-container/timer-container.component';
@NgModule({
  declarations: [
    AppComponent,
    SumOfTenComponent,
    StarComponent,
    DoMultipleComponent,
    TimerContainerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatProgressBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
