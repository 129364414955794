
<div class="scoreboard">
    <span>    <i class="star fas fa-star"></i> {{total}} </span>
</div>

<div>
    hier komt de component
</div>

<div class="timer">
    <mat-progress-bar [value]="timerValue"></mat-progress-bar>
</div>

<div class="result" *ngIf="halt">

    <app-star [value]="timerValue"></app-star>

</div>

<div class="restart">
    <button (click)="resetPoints()">Nieuw spel starten</button>
</div>
