import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-timer-container',
  templateUrl: './timer-container.component.html',
  styleUrls: ['./timer-container.component.scss']
})
export class TimerContainerComponent implements OnInit {

  public numberA: number = 0;
  public numberB: number = 0;
  public result: number = 0;
  public showResult = false;
  public timerValue: number = 0;
  public halt: boolean = false;
  public timeout: number = 2500;
  public total: number = 0;
  public input: string = '';
  public error: string = '';
  public numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  constructor() {
    const storedTotal = localStorage.getItem('total');
    if(!!storedTotal){
      this.total = parseInt(storedTotal);
    }
   }

   @HostListener('window:keydown', ['$event'])
   onKeyDown($event: KeyboardEvent) {
    if(this.halt){
      this.reset();
    } 

    const v = $event.key+ '';
    if(this.numbers.indexOf(v) > -1){
      this.input += v
    }

    if(this.input.length === ('' + this.result).length)
    {
      if(this.input === '' + this.result){
        this.correct();
      }else{
        this.incorrect();
      }

    }
   }

   public correct(): void {
    this.total += (100-this.timerValue)/10;
    this.saveTotal();
    this.halt = true;
    this.showResult = true;
   }

   public incorrect(): void {
    this.error = this.input;
    this.input = '';
    
   }

  public tick(): void {
    if(!this.halt && this.timerValue < 100){
      this.timerValue += 10;
    }
    setTimeout(() => this.tick(), this.timeout);
  }

  private saveTotal(): void {
    localStorage.setItem('total', this.total+'');
  }

  ngOnInit(): void {
    this.tick();
    this.reset();
  }

  get absNumberB(): number {
    return Math.abs(this.numberB);
  }

  public resetPoints(): void {
    this.total = 0;
    this.saveTotal();
    this.reset();
  }

  public reset(): void {
    this.error = '';
    this.input = '';
    this.halt = false;
    this.showResult = false;
    this.numberA = Math.round(Math.random() * 10);
    this.result = Math.round(Math.random() * 10);
    this.numberB = this.result - this.numberA;
    this.timerValue = 0;
  }

}
