
<div class="scoreboard">
    <span>    <i class="star fas fa-star"></i> {{total}} </span>
</div>

<div class="sum">
    <span>
        <b>{{numberA}}</b> 
        <b *ngIf="numberB >= 0"> +</b>
        <b *ngIf="numberB < 0"> -</b> 
        <b> {{ absNumberB }} </b> = 
        <a (click)="reset()" *ngIf="showResult"> {{result}} </a> 
        <a [class.error]='!!error' *ngIf="!showResult" (click)="showResult = true">{{!error ? '?' : error }}</a>
        
    </span>

</div>
<div class="timer">
    <mat-progress-bar [value]="timerValue"></mat-progress-bar>
</div>

<div class="result" *ngIf="halt">

    <app-star [value]="timerValue"></app-star>

</div>

<div class="restart">
    <button (click)="resetPoints()">Nieuw spel starten</button>
</div>
